/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
import type { ChildrenProps } from 'types';

const defaultQueryFn = async ({ queryKey }: { queryKey: any }) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await axios.get(`${queryKey[0]}`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data;
  } catch (error) {
    return { error };
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
    },
  },
});

const ReactQuerySetting = ({ children }: ChildrenProps) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

export default ReactQuerySetting;
