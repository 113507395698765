import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import type { ChildrenProps } from 'types';
import { USER_AUTH_TOKEN } from 'consts/strings';
// import { getToken, clearToken, isLoggedIn } from 'helpers/application/auth';

// type RequestHeaders = {
//   Authorization?: string;
// };

const axiosDefaultInit = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error.response?.status === 401) {
      // redirect to unauthorized page.
      window.location.reload();
    }
    return Promise.reject(error);
  });
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    // const headers = config.headers as RequestHeaders | null;

    const queryParams = new URLSearchParams(window.location.search);

    const userAuthToken = queryParams.get(USER_AUTH_TOKEN);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-param-reassign
    config.headers['Access-Token'] = userAuthToken;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-param-reassign
    config.headers['Api-Request-Version'] = 'bypass';
    // if (!headers?.Authorization) {
    //   const token = getToken();
    //   if (token) {
    //     /* eslint-disable no-param-reassign */
    //     // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    //     // config.headers.Authorization = `Bearer ${token}`;
    //   }
    // }
    return config;
  });
};

axiosDefaultInit();
const AxiosSetting = ({ children }: ChildrenProps) => <>{children}</>;

export default AxiosSetting;
